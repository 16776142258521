import { FC } from 'react';
import { ERROR } from 'constants/icons';
import Image from 'next/image';

export type CustomLineInputProps = {
  id: string;
  name?: string;
  label?: string;
  value?: any;
  type?: string;
  isDisabled?: boolean;
  placeHolder?: string;
  className?: string;
  hasError?: string;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  style?: object;
  inputError?: boolean;
  errorColor?: string;
  autoComplete?: string;
  autoFocus?: boolean;
};

const CustomLineInput: FC<CustomLineInputProps> = ({
  id,
  name,
  label,
  value,
  type,
  placeHolder,
  className,
  hasError = '',
  isDisabled,
  onChange,
  onKeyPress,
  onBlur,
  inputError,
  style,
  errorColor = '',
  autoComplete = '',
  autoFocus,
}) => (
  <div className='tw-w-full'>
    {label && <div className='f-13-500 tw-uppercase tw-text-TEXT_PRIMARY tw-mb-5'>{label}</div>}
    <input
      type={type}
      id={id}
      name={name}
      onWheel={(e) => e.currentTarget.blur()}
      className={`tw-w-full tw-px-3 tw-pb-2 tw-outline-0 tw-border-b  tw-f-[14px] ${
        isDisabled ? 'tw-cursor-not-allowed' : ''
      } ${
        inputError
          ? errorColor || ' tw-text-ORANGE_PRIMARY tw-border-ORANGE_PRIMARY'
          : 'tw-border-DIVIDER_GRAY tw-text-TEXT_GRAY_1 focus:tw-border-ZAMP_PRIMARY'
      } ${hasError ? 'tw-border-RED_PRIMARY' : ''} :  ${className} `}
      placeholder={placeHolder}
      onChange={onChange}
      disabled={isDisabled}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      value={value}
      style={style}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
    />
    {hasError && (
      <div
        className={`f-12-300 tw-text-RED_PRIMARY tw-h-[15px] tw-pt-2 tw-mt-1 tw-leading-none tw-flex tw-items-center`}
      >
        <Image src={ERROR} alt='Error' className='tw-mr-1' width={11.5} height={9} />
        {hasError}
      </div>
    )}
  </div>
);

export default CustomLineInput;
