import { FC, useState } from 'react';
import { SELECTED_GREEN } from 'constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import Image from 'next/image';
import { RootState } from 'store';
import { WalletItemProps } from 'types/romaAccounts';
import Currency from 'components/currency/Currency';
import Tooltip from 'components/currency/Tooltip';
import { ICON_TYPE, POSITION } from 'components/currency/types';

const RenderMenuItem: FC<WalletItemProps> = ({
  item,
  selectedItem,
  handleItemClick,
  parentRef,
  customCurrency = {},
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [topPosition, setTopPosition] = useState<number>(0);

  const { currencyCodeAndNetworkCodeMap, networkCodeAndNameMap } = useAppSelector((state: RootState) => state.config);

  const code = item.code;
  const chainName = code && networkCodeAndNameMap[currencyCodeAndNetworkCodeMap[code]];

  const handleMouseEnter = (e: any) => {
    const parentE = parentRef.current;
    const parentTop = parentE?.scrollTop || 0;
    const elementTop = e.target.offsetTop;
    let top = 0;

    if (elementTop <= 0) top = parentTop - 20;
    else top = elementTop - parentTop - 20;

    setTopPosition(top);
  };

  return (
    <div
      className='tw-py-3 tw-px-6 hover:tw-bg-BASE_SECONDARY tw-flex tw-justify-between'
      onMouseEnter={handleMouseEnter}
      onClick={() => handleItemClick(item)}
      role='presentation'
    >
      <Currency code={code as string} iconSize={ICON_TYPE.LARGE} onIconHover={setShowTooltip} {...customCurrency} />
      {selectedItem?.code === code && <Image src={SELECTED_GREEN} alt='Selected' width={16} height={16} />}
      {chainName && showTooltip && (
        <Tooltip
          code={code as string}
          chainName={chainName}
          position={POSITION.RIGHT}
          style={{ left: '255px', top: `${topPosition}px` }}
        />
      )}
    </div>
  );
};

export default RenderMenuItem;
