import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { defaultFn } from 'destiny/dist/constants/index';

interface CardAccordionProps {
  toggleView?: boolean;
  isOpenControlled?: boolean;
  title: ReactNode;
  children: ReactNode;
  wrapperOpenedClassName?: string;
  wrapperClosedClassName?: string;
  titleOpenedClassName?: string;
  titleClosedClassName?: string;
  bodyOpenedClassName?: string;
  bodyClosedClassName?: string;
  onClick?: (flag?: boolean | null) => void;
  wrapperClassName?: string;
  bodyWrapperClassName?: string;
  bodyWrapperOpenClassName?: string;
  titleLineComponent?: ReactNode;
  titleWrapperClassName?: string;
  maxHeight?: number;
  titleFooter?: ReactNode;
  id?: string;
}

const CardAccordion: FC<CardAccordionProps> = ({
  toggleView = true,
  isOpenControlled = false,
  title = null,
  children = null,
  wrapperOpenedClassName = '',
  wrapperClosedClassName = '',
  titleOpenedClassName = '',
  titleClosedClassName = '',
  bodyOpenedClassName = '',
  bodyClosedClassName = '',
  wrapperClassName = '',
  bodyWrapperClassName = '',
  bodyWrapperOpenClassName = '',
  onClick = defaultFn,
  titleLineComponent = null,
  titleWrapperClassName = '',
  maxHeight,
  titleFooter,
  id = '',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(isOpenControlled);
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    const height = ref?.current?.clientHeight ?? 0;

    if (height !== elementHeight) setElementHeight(height);
  }, [ref?.current?.clientHeight, children]);

  useEffect(() => {
    setOpen(isOpenControlled);
  }, [isOpenControlled]);

  const handleToggle = () => {
    if (toggleView) {
      const toggledValue = !isOpen;

      setOpen(toggledValue);
      onClick(toggledValue);
    } else {
      onClick(null);
    }
  };

  return (
    <div
      className={`tw-relative tw-overflow-hidden ${wrapperClassName} ${
        isOpen ? wrapperOpenedClassName : wrapperClosedClassName
      }`}
      data-testid={`card-accordion-${id}`}
    >
      <div className={`tw-flex tw-items-center ${titleWrapperClassName}`}>
        <div
          className={`tw-flex-grow ${isOpen ? titleOpenedClassName : titleClosedClassName}`}
          onClick={handleToggle}
          role='presentation'
        >
          {title}
        </div>
        {titleLineComponent}
      </div>
      {titleFooter}
      <div
        className={`tw-w-full tw-overflow-hidden tw-transition-all tw-duration-300 ${bodyWrapperClassName} ${
          isOpen ? bodyWrapperOpenClassName : ''
        }`}
        style={{
          ...(maxHeight ? { maxHeight: `${isOpen ? maxHeight : 0}px` } : { height: isOpen ? elementHeight : 0 }),
        }}
      >
        <div ref={ref} className={isOpen ? bodyOpenedClassName : bodyClosedClassName}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CardAccordion;
