import { CSSProperties } from 'react';
import { CustomCurrencyProps } from 'types/romaAccounts';

export enum ICON_TYPE {
  MEDIUM,
  SMALL,
  LARGE,
  XL,
  SMEDIUM,
}

export enum POSITION {
  RIGHT,
  TOP,
}

export interface CurrencyType extends CustomCurrencyProps {
  code: string;
  iconSize?: ICON_TYPE;
  style?: CSSProperties;
  showName?: boolean;
  position?: POSITION;
  tooltipStyle?: CSSProperties;
  tooltipWrapperStyle?: CSSProperties;
  onIconHover?: (flag: boolean) => void;
  nameStyleClass?: string;
  tooltipClassName?: string;
  showIcon?: boolean;
  hideTooltip?: boolean;
  tooltipBodyClassName?: string;
  tooltipContentClassName?: string;
}

export type TooltipProps = {
  code: string;
  chainName: string;
  position?: POSITION;
  style?: CSSProperties;
  tooltipClassName?: string;
  tooltipBodyClassName?: string;
  tooltipContentClassName?: string;
};

export type CaratProps = {
  position?: POSITION;
};
