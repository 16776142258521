import { FC } from 'react';
import { Label } from 'destiny/dist/components/molecules/label';
import { COLORS } from 'destiny/dist/constants/colors';
import { WalletSelectProps } from 'types/romaAccounts';
import { checkIfNumber } from 'utils/common';
import CustomInput, { CustomLineInputProps } from 'components/custom-input/CustomInput';
import { ICONS } from 'components/icons/constants';
import IconWrapper from 'components/icons/IconWrapper';
import WalletSelect from 'components/wallet-select/WalletSelect';

interface CurrencyWalletSelectProps {
  label: string;
  inputProps: CustomLineInputProps;
  showWallet: boolean;
  walletSelectProps: WalletSelectProps;
}

const CurrencyWalletSelect: FC<CurrencyWalletSelectProps> = ({
  label = '',
  inputProps,
  showWallet = false,
  walletSelectProps,
}) => {
  return (
    <div>
      <Label titleClass='f-12-400 tw-text-TEXT_TERTIARY' title={label} />
      <div className='tw-flex tw-items-center tw-w-full tw-relative'>
        <CustomInput
          type='number'
          className='f-20-400 tw-text-TEXT_PRIMARY placeholder:tw-text-TEXT_TERTIARY placeholder:tw-text-xl placeholder:tw-font-light placeholder:tw-tracking-[0.03em] focus:tw-border-ZAMP_PRIMARY focus:tw-outline-0 tw-px-0 tw-pt-5 tw-pb-2'
          placeHolder='0.00'
          errorColor={'tw-text-RED_PRIMARY'}
          onKeyPress={checkIfNumber}
          {...inputProps}
        />
        {showWallet && (
          <WalletSelect
            wrapperClassName='tw-absolute tw-right-0 tw-top-[17px]'
            className='tw-items-center'
            customCurrency={{
              customIconDimensions: { width: 26, height: 26 },
              customIconClassName: 'tw-mr-2.5',
              customCurrencyNameClass: 'f-24-400 tw-text-TEXT_PRIMARY',
            }}
            customDropdownIcon={
              <div className='tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-ml-2.5 tw-rounded-full tw-border hover:tw-border-TEXT_PRIMARY'>
                <IconWrapper icon={ICONS.CHEVRON_DOWN} color={COLORS.TEXT_PRIMARY} width={12} height={12} />
              </div>
            }
            {...walletSelectProps}
          />
        )}
      </div>
    </div>
  );
};

export default CurrencyWalletSelect;
