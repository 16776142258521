import { FC } from 'react';
import { useAppSelector } from 'hooks/toolkit';
import { RootState } from 'store';
import Carat from 'components/currency/Carat';
import { POSITION, TooltipProps } from 'components/currency/types';

const containerStyle = {
  [POSITION.TOP]: 'tw-bottom-[36px]',
  [POSITION.RIGHT]: 'tw-flex',
};

const Tooltip: FC<TooltipProps> = ({
  code = '',
  chainName = '',
  position = POSITION.TOP,
  style = {},
  tooltipClassName = '',
  tooltipBodyClassName = '',
  tooltipContentClassName = '',
}) => {
  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);

  const content = [
    {
      label: 'CHAIN',
      value: chainName,
    },
    {
      label: 'CURRENCY',
      value: currencyCodeAndNameMap[code],
    },
  ];

  return (
    <div className={`tw-absolute ${containerStyle[position]} ${tooltipClassName}`} style={style}>
      {position === POSITION.RIGHT && <Carat position={position} />}
      <div className={`tw-p-6 tw-bg-SECONDARY tw-rounded-2.5 tw-w-fit ${tooltipBodyClassName}`}>
        {content.map((item, index) => {
          const { label, value } = item;

          return (
            <div
              key={`${value}_${index}`}
              className={`tw-flex tw-mb-4 tw-w-full last:tw-mb-0 ${tooltipContentClassName}`}
            >
              <div className='tw-text-TEXT_TERTIARY f-10-600 tw-w-[68px] tw-mr-[10px]'>{label}</div>
              <div className='f-12-300 tw-whitespace-nowrap tw-min-w-[65px]'>{value}</div>
            </div>
          );
        })}
      </div>
      {position === POSITION.TOP && <Carat />}
    </div>
  );
};

export default Tooltip;
