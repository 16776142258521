import { FC } from 'react';
import { CaratProps, POSITION } from 'components/currency/types';

const containerStyle = {
  [POSITION.TOP]: 'tw-border-t-SECONDARY',
  [POSITION.RIGHT]: 'tw-border-r-SECONDARY',
};

const Carat: FC<CaratProps> = ({ position = POSITION.TOP }) => (
  <div
    className={`tw-border-[8px] tw-border-solid tw-border-transparent tw-w-0 tw-h-0 tw-z-1 tw-m-auto ${containerStyle[position]}`}
  />
);

export default Carat;
