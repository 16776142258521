import { FC, useState } from 'react';
import { IMAGE_PLACEHOLDER } from 'constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import Image from 'next/image';
import { RootState } from 'store';
import Tooltip from 'components/currency/Tooltip';
import { CurrencyType, ICON_TYPE, POSITION } from 'components/currency/types';

const iconDimensions = {
  [ICON_TYPE.MEDIUM]: { width: 31, height: 31 },
  [ICON_TYPE.SMALL]: { width: 20, height: 20 },
  [ICON_TYPE.LARGE]: { width: 32, height: 32 },
  [ICON_TYPE.XL]: { width: 39, height: 39 },
  [ICON_TYPE.SMEDIUM]: { width: 24, height: 24 },
};

const iconStyle = {
  [ICON_TYPE.MEDIUM]: 'tw-min-w-[31px]',
  [ICON_TYPE.SMALL]: 'tw-min-w-[20px]',
  [ICON_TYPE.LARGE]: 'tw-min-w-[32px]',
  [ICON_TYPE.XL]: 'tw-min-w-[39px]',
  [ICON_TYPE.SMEDIUM]: 'tw-min-w-[24px]',
};

const currencyStyle = {
  [ICON_TYPE.LARGE]: 'f-16-400',
  [ICON_TYPE.MEDIUM]: 'f-h1-32-400',
  [ICON_TYPE.SMALL]: 'f-14-300',
  [ICON_TYPE.XL]: '',
  [ICON_TYPE.SMEDIUM]: '',
};

const positionStyle = {
  [POSITION.TOP]: 'tw-relative',
  [POSITION.RIGHT]: '',
};

const Currency: FC<CurrencyType> = ({
  code = '',
  iconSize = ICON_TYPE.SMALL,
  style = {},
  showName = true,
  position = POSITION.TOP,
  tooltipStyle = {},
  tooltipWrapperStyle = {},
  nameStyleClass = '',
  customIconDimensions = null,
  customIconClassName = null,
  iconOverrideClassName = 'tw-mr-2',
  customCurrencyNameClass = null,
  onIconHover,
  tooltipClassName = '',
  hideTooltip = false,
  tooltipBodyClassName = '',
  tooltipContentClassName = '',
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { currencyCodeAndIconMap, currencyCodeAndNameMap, currencyCodeAndNetworkCodeMap, networkCodeAndNameMap } =
    useAppSelector((state: RootState) => state.config);

  const chainName = networkCodeAndNameMap[currencyCodeAndNetworkCodeMap[code]];

  const handleMouseEnter = () => {
    chainName && setShowTooltip(true);
    onIconHover && onIconHover(true);
  };

  const handleMouseLeave = () => {
    chainName && setShowTooltip(false);
    onIconHover && onIconHover(false);
  };

  const iconDimensionsObj = customIconDimensions ?? iconDimensions[iconSize];

  return (
    <div className='tw-flex tw-items-center' style={style}>
      <div className={`tw-flex tw-justify-center ${positionStyle[position]}`} style={tooltipWrapperStyle}>
        {!onIconHover && chainName && showTooltip && !hideTooltip && (
          <Tooltip
            code={code}
            chainName={chainName}
            position={position}
            style={tooltipStyle}
            tooltipClassName={tooltipClassName}
            tooltipBodyClassName={tooltipBodyClassName}
            tooltipContentClassName={tooltipContentClassName}
          />
        )}
        <Image
          src={currencyCodeAndIconMap[code] || IMAGE_PLACEHOLDER}
          alt='currency-logo'
          className={`${customIconClassName ?? `${iconStyle[iconSize]} ${iconOverrideClassName}`} ${
            chainName ? 'tw-cursor-pointer' : ''
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          priority={true}
          {...iconDimensionsObj}
        />
      </div>
      {showName && (
        <div className={`tw-whitespace-nowrap ${customCurrencyNameClass ?? currencyStyle[iconSize]} ${nameStyleClass}`}>
          {currencyCodeAndNameMap[code]}
        </div>
      )}
    </div>
  );
};

export default Currency;
