import React, { useRef, useState } from 'react';
import { DROPDOWN_ICON } from 'constants/icons';
import mixpanelEvents from 'constants/mixpanel';
import { useOnClickOutside } from 'hooks';
import Image from 'next/image';
import { MenuItem } from 'types';
import { WalletSelectProps } from 'types/romaAccounts';
import { DestCurrency, SourceCurrency } from 'types/transactions';
import { trackMixpanel } from 'utils/mixpanel';
import Currency from 'components/currency/Currency';
import { ICON_TYPE } from 'components/currency/types';
import RenderMenuItem from 'components/wallet-select/RenderMenuItem';

const WalletSelect: React.FC<WalletSelectProps> = ({
  wrapperClassName = '',
  className = '',
  id = '',
  options,
  selectedItem,
  onMenuItemClick,
  hasError = false,
  isDisabled = false,
  customCurrency = {},
  customDropdownIcon = null,
}) => {
  const [isOpen, toggleOpen] = useState(false);

  const ref = useRef(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    toggleOpen(false);
  });

  const onDropdownClick = () => {
    if (isDisabled) {
      toggleOpen(false);
    } else {
      !isOpen && trackMixpanel(mixpanelEvents.TRANSFER_CHANGE_CURRENCY_DROPDOWN_CLICK, {});
      toggleOpen(!isOpen);
    }
  };

  const handleItemClick = (menu: MenuItem | SourceCurrency | DestCurrency) => {
    toggleOpen(false);
    trackMixpanel(mixpanelEvents.TRANSFER_SELECT_CURRENCY_CLICK, {});
    onMenuItemClick(menu);
  };

  return (
    <div className={wrapperClassName}>
      <div className={`tw-relative tw-text-base tw-leading-5 tw-text-TEXT_PRIMARY`} ref={ref}>
        <div
          id={id}
          className={`tw-relative tw-w-full tw-flex ${
            isDisabled ? 'tw-cursor-default' : 'tw-cursor-pointer'
          } tw-rounded-[10px]
        ${hasError ? 'tw-outline tw-outline-1 tw-outline-ERROR_RED' : ''} ${className}`}
          role='presentation'
          onClick={() => onDropdownClick()}
        >
          {selectedItem?.code ? (
            <Currency code={selectedItem.code as string} iconSize={ICON_TYPE.MEDIUM} {...customCurrency} />
          ) : (
            <div className='f-16-400 tw-flex tw-flex-col tw-justify-center tw-leading-5 tw-text-TEXT_TERTIARY '>
              Select
            </div>
          )}
          {options.length > 1 &&
            (customDropdownIcon ?? (
              <Image
                src={DROPDOWN_ICON}
                alt='dropdown'
                draggable='false'
                width={12}
                height={12}
                className='tw-ml-2 tw-min-w-[12px]'
              />
            ))}
        </div>
        {isOpen && !!options.length && options.length > 1 && (
          <div className='tw-z-10 tw-absolute tw-top-[-50%] tw-left-full tw-ml-[22px]'>
            <div
              ref={dropdownRef}
              className={`tw-rounded-[10px] tw-cursor-pointer tw-max-h-[200px] tw-overflow-y-scroll tw-border tw-border-b-DIVIDER_GRAY tw-shadow-[-1px_2px_10px_rgba(37,70,245,0.1)] tw-w-[250px]`}
            >
              <>
                {options.map((item) => (
                  <RenderMenuItem
                    item={item}
                    selectedItem={selectedItem}
                    handleItemClick={handleItemClick}
                    parentRef={dropdownRef}
                    key={item.code}
                  />
                ))}
              </>
            </div>
          </div>
        )}
      </div>
      {hasError && <div className={`f-12-300 tw-text-ERROR_RED mt-2 tw-h-5`}>{hasError}</div>}
    </div>
  );
};

export default WalletSelect;
